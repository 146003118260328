import log from "loglevel";
import { RootStore } from "./root-store";
import { makeAutoObservable } from "mobx";
import { toast } from "react-toastify";
import Api from "@api/api-content-menu";

class MenuStore {

    private rootStore: RootStore;

    private _menu = [];

    get menu() {
        return this._menu;
    }
    set menu(val: any) {
        this._menu = val;
    }

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    getMenuItems(items: any[], parent_id: any = null) {
        let res: any = [];
        for (let item of items) {
            if (item.parent_id === parent_id) {
                item.childs = this.getMenuItems(items, item.section_id);
                res.push(item);
            }
        }
        res.sort((a: any, b: any) => a.order - b.order);
        return res;
    }

    async getMenu() {
        log.debug("getMenu");
        try {
            const res = await Api.menu();
            log.debug(res.data);
            if (res.data.status === 'OK') {
                this.menu = res.data.sections;
                return this.getMenuItems(res.data.sections, 0);
            }
        } catch (ex: any) {
            log.debug("ERROR", ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async save(data: any) {
        log.debug("save data", data);
        try {
            const res = await Api.save(data);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                return res.data;
            } else {
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async delete(section_id: number) {
        log.debug("delete section_id", section_id);
        try {
            const res = await Api.delete(section_id);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                toast.success("Done", {
                    autoClose: 500,
                });
                return true;
            } else {
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

}

export default MenuStore;