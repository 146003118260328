import { instance } from "./service";

class Api {

    config() {
        return instance.get("/tickets/config");
    }

    tickets(data: any) {
        return instance.post("/tickets", {
            data: data
        });
    }

    ticket(ticket_id: number) {
        return instance.get("/ticket/" + ticket_id);
    }

    save(data: any) {
        return instance.post("/tickets/save", data);
    }

    disable(ticket_id: number) {
        return instance.get("/tickets/disable/" + ticket_id);
    }

    delete(ticket_id: number) {
        return instance.get("/tickets/delete/" + ticket_id);
    }

    upload(attaches: any[]) {
        const formData = new FormData();
        attaches.forEach(file => {
            formData.append("files", file);
        });
        return instance.post("/tickets/upload", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
    }

    send_message(ticket_id: number, message: string, hash: string) {
        return instance.post("/tickets/message", {
            ticket_id: ticket_id,
            message: message,
            hash: hash
        });
    }

}

export default new Api();
