import log from "loglevel";
import { instance } from "./service";

class ApiStatistics {

    stats() {
        log.debug("stats");
        return instance.get("/stats");
    }

}

export default new ApiStatistics();
