import { RootStore } from "./root-store";
import log from "loglevel";
import { toast } from "react-toastify";
import { makeAutoObservable } from "mobx";
import Api from "@api/api-support";


class SupportStore {

    private rootStore: RootStore;

    private _users = [];

    get users() {
        return this._users;
    }
    set users(val: any) {
        this._users = val;
    }

    async getUsers(data: any, callback: any, settings: any) {
        log.debug("getUsers", data, callback, settings);
        try {
            const res = await Api.users(data);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                callback(res.data);
            }
        } catch (ex: any) {
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async getRules(user_id: number) {
        log.debug("getRules", user_id);
        try {
            const res = await Api.rules(user_id);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                return res.data.rules;
            } else {
                toast.error(res.data?.message);
            }
        } catch (ex: any) {
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async save(data: any) {
        log.debug("save", data);
        try {
            const res = await Api.save(data);
            if (res.data.status === 'OK') {
                return true;
            } else {
                toast.error(res.data?.message);
            }
        } catch (ex: any) {
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async disable(user_id: number) {
        log.debug("disable user_id", user_id);
        try {
            const res = await Api.disable(user_id);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                toast.success("Done", {
                    autoClose: 500,
                });
                return res.data;
            } else {
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async delete(user_id: number) {
        log.debug("delete user_id", user_id);
        try {
            const res = await Api.delete(user_id);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                toast.success("Done", {
                    autoClose: 500,
                });
                return res.data;
            } else {
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }
    
    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

}

export default SupportStore;