import log from "loglevel";
import { RootStore } from "./root-store";
import { toast } from "react-toastify";
import Api from "@api/api-settings-cron";
const { makeAutoObservable } = require("mobx");

class CronStore {

    private rootStore: RootStore;

    private _cron = [];

    get cron() {
        return this._cron;
    }
    set cron(val: any) {
        this._cron = val;
    }

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    async getCron() {
        log.debug("getCron");
        this.rootStore.setShowProgressBar(true);
        try {
            const res = await Api.cron();
            log.debug(res.data);
            if (res.data.status === 'OK') {
                this.cron = res.data.cron;
            }
        } catch (ex: any) {
            log.debug("ERROR", ex);
            toast.error(ex.response?.data.message || ex.message);
        }
        this.rootStore.setShowProgressBar(false);
    }

    async getJob(cron_id: number) {
        log.debug("getJob", cron_id);
        this.rootStore.setShowProgressBar(true);
        try {
            const res = await Api.job(cron_id);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                this.rootStore.setShowProgressBar(false);
                return res.data;
            }
        } catch (ex: any) {
            log.debug("ERROR", ex);
            toast.error(ex.response?.data.message || ex.message);
        }
        this.rootStore.setShowProgressBar(false);
    }

    async disable(cron_id: number) {
        log.debug("disable cron_id", cron_id);
        try {
            const res = await Api.disable(cron_id);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                toast.success("Done", {
                    autoClose: 500,
                });
                await this.getCron();
            } else {
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async start(cron_id: number) {
        log.debug("start cron_id", cron_id);
        try {
            const res = await Api.start(cron_id);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                toast.success("Done", {
                    autoClose: 500,
                });
                await this.getCron();
            } else {
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async save(data: any) {
        log.debug("save data", data);
        try {
            const res = await Api.save(data);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                return res.data;
            } else {
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

}

export default CronStore;