import React, {useEffect} from 'react';
import { observer } from "mobx-react-lite";
import Sidebar from "@components/sidebar/sidebar";
import Navbar from "@components/navbar/navbar";
import Content from "@components/content/content";
import Footer from "@components/footer/footer";
import ProgressBar from "@components/progress-bar/progress-bar";
import { useStore } from "@hooks/use-store";

const Index = () => {

    const { authStore } = useStore();

    useEffect(() => {
        authStore.getAcl().then();
    }, []);

    return (
        <>
            <ProgressBar />
            <div className="App wrapper">
                {authStore.acl &&
                    <>
                        <Sidebar />
                        <div className="main">
                            <Navbar />
                            <Content />
                            <Footer />
                        </div>
                    </>
                }
            </div>
        </>
    );
};

export default observer(Index);
