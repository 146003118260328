import log from "loglevel";
import { toast } from "react-toastify";
import { makeAutoObservable } from "mobx";

import { RootStore } from "./root-store";
import Api from "@api/api-statistics";


class StatisticsStore {

    private rootStore: RootStore;

    private _stats: any;

    get stats() {
        return this._stats;
    }
    set stats(val) {
        this._stats = val;
    }


    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    async load() {
        log.debug("load");
        try {
            const res = await Api.stats();
            log.debug(res.data);
            if (res.data.status === 'OK') {
                this.stats = res.data.data;
            }
        } catch (ex: any) {
            toast.error(ex.response?.data.message || ex.message);
        }
    }

}

export default StatisticsStore;