import { instance } from "./service";

class ApiServices {

    services() {
        return instance.get("/settings/services");
    }

    disable(service_id: number) {
        return instance.get("/settings/services/disable/" + service_id);
    }

}

export default new ApiServices();
