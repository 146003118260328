import React from 'react';
import { observer } from "mobx-react-lite";
import { useStore } from "@hooks/use-store";
import styles from "./progress-bar.module.scss";

const ProgressBar = () => {

    const { rootStore } = useStore();

    return (
        <>
            {rootStore.showProgressBar &&
                <div className={styles.container}>
                    <div className={styles["indeterminate-progress-bar"]}>
                        <div className={styles["indeterminate-progress-bar__progress"]} />
                    </div>
                </div>
            }
        </>
    );

};

export default observer(ProgressBar);
