import { instance } from "./service";

class ApiPages {

    pages(data: any) {
        return instance.post("/content/pages", {
            data: data
        });
    }

    save(data: any) {
        return instance.post("/content/page", data);
    }

    disable(page_id: number) {
        return instance.get("/content/page/disable/" + page_id);
    }

    delete(page_id: number) {
        return instance.get("/content/page/delete/" + page_id);
    }

}

export default new ApiPages();
