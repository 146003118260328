import { RootStore } from "./root-store";
import log from "loglevel";
import { toast } from "react-toastify";
import { makeAutoObservable } from "mobx";
import Api from "@api/api-content-notifications";

class NotificationsStore {

    private rootStore: RootStore;
    private _toast: any;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    async getNotifications(data: any, callback: any, settings: any) {
        log.debug("getNotifications", data, callback, settings);
        try {
            const res = await Api.notifications(data);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                callback(res.data);
            }
        } catch (ex: any) {
            log.debug("ERROR", ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async save(data: any) {
        log.debug("save data", data);
        try {
            const res = await Api.save(data);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                return res.data;
            } else {
                toast.dismiss(this._toast);
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async disable(notify_id: number) {
        log.debug("disable notify_id", notify_id);
        try {
            const res = await Api.disable(notify_id);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                toast.success("Done", {
                    autoClose: 500,
                });
                return res.data;
            } else {
                toast.dismiss(this._toast);
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async delete(notify_id: number) {
        log.debug("delete notify_id", notify_id);
        try {
            const res = await Api.delete(notify_id);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                toast.success("Done", {
                    autoClose: 500,
                });
                return res.data;
            } else {
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

}

export default NotificationsStore;