import log from "loglevel";
import React, { Suspense, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { useStore } from "@hooks/use-store";
import { TypeRoute } from "../types/type-route";
import routes from '../routing/routes';
import NotFound from "@layouts/not-found/NotFound";
import Loading from "@components/loading/Loading";

const MainRoutes = () => {

    const { authStore } = useStore();

    /**
     * Объединение маршрутов из иерархического в плоский список для добавления в роуты приложения
     * @param list
     */
    const mergeRoutes = (list: TypeRoute[]) => {
        let res: any[] = [];
        for (let route of list) {
            if (!route.acl || authStore.checkAcl(route.acl)) {
                res.push(route);
                if (route.childs) {
                    res = res.concat(mergeRoutes(route.childs));
                }
            }
        }
        return res;
    }

    /**
     * Генерация роутов
     * @param list
     */
    const addRoutes = (list: TypeRoute[]) => {
        const res = mergeRoutes(list);
        return res.map((route: TypeRoute, idx: number) => {
            return (
                route.element && (
                    <Route
                        key={idx}
                        path={route.path}
                        element={<route.element />}
                    />
                )
            )
        });
    }

    return (
        <Suspense fallback={<Loading/>}>
            <Routes>
                <Route index element={<Navigate to="/main" replace />} />
                { addRoutes(routes) }
                <Route path='*' element={<NotFound/>} />
            </Routes>
        </Suspense>
    );
}

export default observer(MainRoutes);