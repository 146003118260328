import log from "loglevel";
import { toast } from "react-toastify";
import { RootStore } from "./root-store";
import { toJS } from "mobx";
import { makeAutoObservable } from "mobx";
import Api from "@api/api-finance-settings";


class FinanceSettingsStore {

    private rootStore: RootStore;

    private _data = {
        exchange_koef: 0,
        min_fund_sum: 0,
        price_super_search_buy: 0,
        price_pros_margin: 0,
        price_ssn_new_search: 0,
        price_ssn_super_search: 0,
        price_used: 0,
        price_mmn_search: 0,
        price_premium1_cs: 0,
        price_premium1_cr: 0,
        price_premium1_dl: 0,
        price_premium1_dob: 0,
        price_premium1_ssn: 0,
        price_premium2_cs: 0,
        price_premium2_cr: 0,
        price_premium2_dl: 0,
        price_premium2_dob: 0,
        price_premium2_ssn: 0,
        price_trusted_emails: 0
    }

    // region getters & setters
    get data() {
        return this._data;
    }
    set data(val: any) {
        this._data = val;
    }

    get exchange_koef() {
        return this._data.exchange_koef;
    }
    set exchange_koef(val) {
        this._data.exchange_koef = val;
    }

    get min_fund_sum() {
        return this._data.min_fund_sum;
    }
    set min_fund_sum(val) {
        this._data.min_fund_sum = val;
    }

    get price_super_search_buy() {
        return this._data.price_super_search_buy;
    }
    set price_super_search_buy(val) {
        this._data.price_super_search_buy = val;
    }

    get price_used() {
        return this._data.price_used;
    }
    set price_used(val) {
        this._data.price_used = val;
    }

    get price_trusted_emails() {
        return this._data.price_trusted_emails;
    }
    set price_trusted_emails(val) {
        this._data.price_trusted_emails = val;
    }

    get price_mmn_search() {
        return this._data.price_mmn_search;
    }
    set price_mmn_search(val) {
        this._data.price_mmn_search = val;
    }

    get price_pros_margin() {
        return this._data.price_pros_margin;
    }
    set price_pros_margin(val) {
        this._data.price_pros_margin = val;
    }

    get price_ssn_new_search() {
        return this._data.price_ssn_new_search;
    }
    set price_ssn_new_search(val) {
        this._data.price_ssn_new_search = val;
    }

    get price_ssn_super_search() {
        return this._data.price_ssn_super_search;
    }
    set price_ssn_super_search(val) {
        this._data.price_ssn_super_search = val;
    }

    get price_premium1_cs() {
        return this._data.price_premium1_cs;
    }
    set price_premium1_cs(val) {
        this._data.price_premium1_cs = val;
    }

    get price_premium1_cr() {
        return this._data.price_premium1_cr;
    }
    set price_premium1_cr(val) {
        this._data.price_premium1_cr = val;
    }

    get price_premium1_dl() {
        return this._data.price_premium1_dl;
    }
    set price_premium1_dl(val) {
        this._data.price_premium1_dl = val;
    }

    get price_premium1_dob() {
        return this._data.price_premium1_dob;
    }
    set price_premium1_dob(val) {
        this._data.price_premium1_dob = val;
    }

    get price_premium1_ssn() {
        return this._data.price_premium1_ssn;
    }
    set price_premium1_ssn(val) {
        this._data.price_premium1_ssn = val;
    }

    get price_premium2_cs() {
        return this._data.price_premium2_cs;
    }
    set price_premium2_cs(val) {
        this._data.price_premium2_cs = val;
    }

    get price_premium2_cr() {
        return this._data.price_premium2_cr;
    }
    set price_premium2_cr(val) {
        this._data.price_premium2_cr = val;
    }

    get price_premium2_dl() {
        return this._data.price_premium2_dl;
    }
    set price_premium2_dl(val) {
        this._data.price_premium2_dl = val;
    }

    get price_premium2_dob() {
        return this._data.price_premium2_dob;
    }
    set price_premium2_dob(val) {
        this._data.price_premium2_dob = val;
    }

    get price_premium2_ssn() {
        return this._data.price_premium2_ssn;
    }
    set price_premium2_ssn(val) {
        this._data.price_premium2_ssn = val;
    }
    // endregion


    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    async load() {
        log.debug("load");
        try {
            const res = await Api.settings();
            log.debug(res.data);
            if (res.data.status === 'OK') {
                const settings = res.data.data.setting;
                this.exchange_koef = settings.filter((item: any) => item.name === 'exchange_koef').pop().val;
                this.min_fund_sum = settings.filter((item: any) => item.name === 'min_fund_sum').pop().val;
                this.price_super_search_buy = settings.filter((item: any) => item.name === 'price_super_search_buy').pop().val;
                this.price_pros_margin = settings.filter((item: any) => item.name === 'price_pros_margin').pop().val;
                this.price_ssn_new_search = settings.filter((item: any) => item.name === 'price_ssn_new_search').pop().val;
                this.price_ssn_super_search = settings.filter((item: any) => item.name === 'price_ssn_super_search').pop().val;
                this.price_used = settings.filter((item: any) => item.name === 'price_used').pop().val;
                this.price_trusted_emails = settings.filter((item: any) => item.name === 'price_trusted_emails').pop().val;
                this.price_mmn_search = settings.filter((item: any) => item.name === 'price_mmn_search').pop().val;

                const premium = res.data.data.premium;
                this.price_premium1_cs = premium.filter((item: any) => item.source_id === 1 && item.name === 'CS').pop().price;
                this.price_premium1_cr = premium.filter((item: any) => item.source_id === 1 && item.name === 'CR').pop().price;
                this.price_premium1_dl = premium.filter((item: any) => item.source_id === 1 && item.name === 'DL').pop().price;
                this.price_premium1_dob = premium.filter((item: any) => item.source_id === 1 && item.name === 'DOB').pop().price;
                this.price_premium1_ssn = premium.filter((item: any) => item.source_id === 1 && item.name === 'SSN').pop().price;

                this.price_premium2_cs = premium.filter((item: any) => item.source_id === 2 && item.name === 'CS').pop().price;
                this.price_premium2_cr = premium.filter((item: any) => item.source_id === 2 && item.name === 'CR').pop().price;
                this.price_premium2_dl = premium.filter((item: any) => item.source_id === 2 && item.name === 'DL').pop().price;
                this.price_premium2_dob = premium.filter((item: any) => item.source_id === 2 && item.name === 'DOB').pop().price;
                this.price_premium2_ssn = premium.filter((item: any) => item.source_id === 2 && item.name === 'SSN').pop().price;
            }
        } catch (ex: any) {
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async save() {
        log.debug("save", toJS(this.data));
        try {
            this.rootStore.setShowProgressBar(true);
            const res = await Api.saveSettings(this.data);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                toast.success("Saved successfully");
            } else {
                toast.error(res.data.message);
            }
            this.rootStore.setShowProgressBar(false);
        } catch (ex: any) {
            toast.error(ex.response?.data.message || ex.message);
            this.rootStore.setShowProgressBar(false);
        }
    }

}

export default FinanceSettingsStore;