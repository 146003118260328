import log from "loglevel";
import { toast } from "react-toastify";
import Api from "@api/api-content-pages";
import { makeAutoObservable } from "mobx";
import { RootStore } from "./root-store";

class PagesStore {

    private rootStore: RootStore;
    private _toast: any;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    async getPages(data: any, callback: any, settings: any) {
        log.debug("getPages", data, callback, settings);
        try {
            const res = await Api.pages(data);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                callback(res.data);
            }
        } catch (ex: any) {
            log.debug("ERROR", ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async save(data: any) {
        log.debug("save data", data);
        try {
            const res = await Api.save(data);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                return res.data;
            } else {
                toast.dismiss(this._toast);
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async disable(page_id: number) {
        log.debug("disable page_id", page_id);
        try {
            const res = await Api.disable(page_id);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                toast.success("Done", {
                    autoClose: 500,
                });
                return res.data;
            } else {
                toast.dismiss(this._toast);
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async delete(page_id: number) {
        log.debug("delete page_id", page_id);
        try {
            const res = await Api.delete(page_id);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                toast.success("Done", {
                    autoClose: 500,
                });
                return res.data;
            } else {
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

}

export default PagesStore;