import { instance } from "./service";

class ApiFullzParser {

    config() {
        return instance.get("/data/fullz/parser/config");
    }

}

export default new ApiFullzParser();
