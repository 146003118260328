import log from "loglevel";
import { toJS } from "mobx";
import { toast } from "react-toastify";
import { RootStore } from "./root-store";
import { makeAutoObservable } from "mobx";
import Api from "@api/api-data-fullz-databases";


class FullzDatabasesStore {

    private rootStore: RootStore;

    private _databases = [];

    private _toast: any;

    // region Getters & Setters
    get databases() {
        return this._databases;
    }
    set databases(val: any) {
        this._databases = val;
    }

    get formats() {
        return toJS(this.rootStore.fullzFormatsStore.formats);
    }
    // endregion

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    async loadData() {
        this.rootStore.setShowProgressBar(true);
        try {
            await this.rootStore.fullzFormatsStore.loadFormats();
            await this.loadDatabases();
        } catch (ex: any) {
            toast.error(ex.message);
        }
        this.rootStore.setShowProgressBar(false);
    }

    async loadDatabases() {
        log.debug("loadDatabases");
        try {
            const res = await Api.databases();
            log.debug(res.data);
            if (res.data.status === 'OK') {
                this.databases = res.data.data;
            }
        } catch (ex: any) {
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async save(data: any) {
        log.debug("save data", data);
        try {
            const res = await Api.save(data);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                return true;
            } else {
                toast.dismiss(this._toast);
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async disable(source_id: number) {
        log.debug("disable source_id", source_id);
        try {
            const res = await Api.disable(source_id);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                toast.success("Done", {
                    autoClose: 500,
                });
                return res.data;
            } else {
                toast.dismiss(this._toast);
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async delete(source_id: number) {
        log.debug("delete source_id", source_id);
        try {
            const res = await Api.delete(source_id);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                toast.success("Done", {
                    autoClose: 500,
                });
                return res.data;
            } else {
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

}

export default FullzDatabasesStore;