import React from 'react';
import MainRoutes from "@routing/main-routes";

const Content = () => {
    return (
        <main className="content">
            <div className="container-fluid p-0">
                <MainRoutes/>
            </div>
        </main>
    );
};

export default Content;
