import React from "react";
import { Route, Routes } from "react-router-dom";
import { observer } from "mobx-react-lite";

import ProtectedRoute from "./protected-route";
import Index from "../views/index/index";
import Login from "@views/login/login";
import Logout from "@views/logout/logout";

const AppRoutes = (props: any) => {
    return (
        <Routes>
            <Route path="login" element={<Login/>} />
            <Route path="logout" element={<Logout/>} />
            <Route path='*' element={<ProtectedRoute><Index/></ProtectedRoute>} />
            <Route path='*' element={<Index/>} />
        </Routes>
    );
}

export default observer(AppRoutes);