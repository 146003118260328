import log from "loglevel";
import { RootStore } from "./root-store";
import { toast } from "react-toastify";
import Api from "@api/api-requests-mmn";
import { makeAutoObservable } from "mobx";


class MmnStore {

    private rootStore: RootStore;
    private _toast: any;
    private _config: any;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    get config() {
        return this._config;
    }
    set config(val: any) {
        this._config = val;
    }

    async loadMmnConfig() {
        log.debug("loadMmnConfig");
        try {
            const res = await Api.config();
            log.debug(res.data);
            if (res.data.status === 'OK') {
                this.config = res.data.config;
            }
        } catch (ex: any) {
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async getMmn(data: any, callback: any, settings: any) {
        log.debug("getMmn", data, callback, settings);
        try {
            const res = await Api.mmn(data);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                callback(res.data);
            }
        } catch (ex: any) {
            log.debug("ERROR", ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async save(data: any) {
        log.debug("save data", data);
        try {
            const res = await Api.save(data);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                return res.data;
            } else {
                toast.dismiss(this._toast);
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async disable(mmn_req_id: number) {
        log.debug("disable mmn_req_id", mmn_req_id);
        try {
            const res = await Api.disable(mmn_req_id);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                toast.success("Done", {
                    autoClose: 500,
                });
                return res.data;
            } else {
                toast.dismiss(this._toast);
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async delete(mmn_req_id: number) {
        log.debug("delete mmn_req_id", mmn_req_id);
        try {
            const res = await Api.delete(mmn_req_id);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                toast.success("Done", {
                    autoClose: 500,
                });
                return res.data;
            } else {
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

}

export default MmnStore;