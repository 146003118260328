import log from "loglevel";
import { toast } from "react-toastify";
import { RootStore } from "./root-store";
import { makeAutoObservable } from "mobx";
import Api from "@api/api-data-fullz-formats";


class FullzFormatsStore {

    private rootStore: RootStore;

    private _formats = [];

    private _toast: any;

    // region Getters & Setters
    get formats() {
        return this._formats;
    }
    set formats(val: any) {
        this._formats = val;
    }
    // endregion

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    async loadFormats() {
        log.debug("loadFormats");
        try {
            const res = await Api.formats();
            log.debug(res.data);
            if (res.data.status === 'OK') {
                this.formats = res.data.data;
            }
        } catch (ex: any) {
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async save(data: any) {
        log.debug("save data", data);
        try {
            const res = await Api.save(data);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                return true;
            } else {
                toast.dismiss(this._toast);
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async disable(format_id: number) {
        log.debug("disable format_id", format_id);
        try {
            const res = await Api.disable(format_id);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                toast.success("Done", {
                    autoClose: 500,
                });
                return res.data;
            } else {
                toast.dismiss(this._toast);
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async delete(format_id: number) {
        log.debug("delete format_id", format_id);
        try {
            const res = await Api.delete(format_id);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                toast.success("Done", {
                    autoClose: 500,
                });
                return res.data;
            } else {
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

}

export default FullzFormatsStore;