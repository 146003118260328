import log from "loglevel";
import { toast } from "react-toastify";
import { RootStore } from "./root-store";
import { makeAutoObservable } from "mobx";
import { MSG } from "@config/messages";
import Api from "@api/api-tickets";


class TicketsStore {

    private rootStore: RootStore;

    private _config = {
        tickets_statuses: [],
        tickets_groups: [],
        tickets_users: [],
        upload: null
    };
    private _filters = {
        ticket_id: "",
        ticket_group_id: [],
        ticket_status_id: [],
        user_id: [],
        period: {
            from: "",
            to: ""
        },
    };
    private _ticket_id = 0;
    private _toast: any;

    get config() {
        return this._config;
    }
    set config(val: any) {
        this._config = val;
    }

    get filters() {
        return this._filters;
    }
    set filters(val: any) {
        this._filters = val;
    }

    get ticket_id() {
        return this._ticket_id;
    }
    set ticket_id(val: any) {
        this._ticket_id = val;
    }

    setFilterValue(field: string, val: string) {
        log.debug("setFilterValue field", field, val);
        // @ts-ignore
        this.filters[field] = val;
    }

    setFilterValueSubfield(field: string, subfield: string, val: string) {
        // @ts-ignore
        this.filters[field][subfield] = val;
    }

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    async loadConfig() {
        log.debug("loadConfig");
        try {
            const res = await Api.config();
            this.rootStore.setShowProgressBar(true);
            if (res.data.status === 'OK') {
                this.config.tickets_statuses = res.data.tickets_statuses.map((row: any) => {
                    let label = row.title;
                    switch (row.ticket_status_id) {
                        case 2:
                            label = 'Await support';
                            break;
                        case 3:
                            label = 'Await customer';
                            break;
                    }
                    return {
                        value: row.ticket_status_id,
                        label: label
                    }
                });
                this.config.tickets_groups = res.data.tickets_groups.map((row: any) => {
                    return {
                        value: row.ticket_group_id,
                        label: row.title
                    }
                });
                this.config.tickets_users = res.data.tickets_users.map((row: any) => {
                    return {
                        value: row.user_id,
                        label: row.login
                    }
                });
                this.config.upload = res.data.upload;
            }
        } catch (ex: any) {
            toast.error(ex.response?.data.message || ex.message);
        }
        this.rootStore.setShowProgressBar(false);
    }

    async getTickets(data: any, callback: any, settings: any) {
        log.debug("getTickets", data, callback, settings);
        try {
            data.filters = {
                ticket_id: this.filters.ticket_id,
                ticket_status_id: this.filters.ticket_status_id?.value,
                ticket_group_id: this.filters.ticket_group_id?.value,
                user_id: this.filters.user_id?.value,
                period: this.filters.period
            }
            const res = await Api.tickets(data);
            log.debug("TICKETS", res.data);
            if (res.data?.status === 'OK') {
                callback(res.data);
            }
        } catch (ex: any) {
            log.debug("ERROR", ex);
            toast.error(ex.response?.data.message || ex.message);
        }
        this.rootStore.setShowProgressBar(false);
    }

    async getTicket(ticket_id: number) {
        log.debug("loadConfig");
        let result = null;
        try {
            this.rootStore.setShowProgressBar(true);
            const res = await Api.ticket(ticket_id);
            console.log("TICKET", res);
            if (res.data.status === 'OK') {
                result = res.data.ticket;
            }
        } catch (ex: any) {
            toast.error(ex.response?.data.message || ex.message);
        }
        this.rootStore.setShowProgressBar(false);
        return result;
    }

    async save(data: any) {
        log.debug("save data", data);
        try {
            const res = await Api.save(data);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                return res.data;
            } else {
                toast.dismiss(this._toast);
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async disable(ticket_id: number) {
        log.debug("disable ticket_id", ticket_id);
        try {
            const res = await Api.disable(ticket_id);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                toast.success("Done", {
                    autoClose: 500,
                });
                return res.data;
            } else {
                toast.dismiss(this._toast);
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async delete(ticket_id: number) {
        log.debug("delete ticket_id", ticket_id);
        try {
            const res = await Api.delete(ticket_id);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                toast.success("Done", {
                    autoClose: 500,
                });
                return res.data;
            } else {
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async sendMessage(message: string, attaches: any[]) {
        log.debug(`sendMessage ticket_id:${this.ticket_id}`, message, attaches);
        this._toast = toast.info(MSG.MSG_LOADING);
        try {
            let res: any = await this.uploadFiles(attaches);
            console.log(res);
            if (res && res.data.status === 'OK') {
                res = await Api.send_message(this.ticket_id, message, res.data.hash);
                console.log(res);
                if (res.data.status === 'OK') {
                    this.rootStore.modalTicketsStore.clear();
                    await this.getTicket(this.ticket_id);
                    toast.dismiss(this._toast);
                    return true;
                } else {
                    toast.dismiss(this._toast);
                    toast.error(res.data.message);
                }
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.dismiss(this._toast);
            toast.error(ex.response?.data.message || ex.message);
        }
        return false;
    }

    /**
     * Отправка аттачей сообщения
     */
    async uploadFiles(attaches: any[]) {
        log.debug(`uploadFiles`);
        try {
            return await Api.upload(attaches);
        } catch (ex: any) {
            log.debug(ex);
            toast.dismiss(this._toast);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    clearFilters() {
        this.filters = {
            ticket_id: "",
            ticket_group_id: [],
            ticket_status_id: [],
            user_id: [],
            period: {
                from: "",
                to: ""
            }
        }
    }

}

export default TicketsStore;