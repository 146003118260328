import React from "react";
import styles from './NotFound.module.scss';


const NotFound = () => {

    return (
        <div className={styles.page404}>
            <h1>Page Not Found</h1>
            <div className={styles.descr}>
                This page you're looking for might renamed, removed or might never exists on this site.
            </div>
        </div>
    );
}

export default NotFound;