export const ACL = {
    user_balance: 1,
    requests_mmn: 2,
    requests_trusted_emails: 3,
    tickets_finance: 4,
    tickets_search: 5,
    tickets_refunds: 6,
    tickets_error: 7,
    tickets_cooperation: 8,
    add_news: 9,
    add_pages: 10,
    add_notify: 11
}