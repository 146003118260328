import { instance } from "./service";

class ApiAuth {

    captcha() {
        return instance.get("/captcha");
    }

    login(email: string, password: string, captcha: string) {
        return instance.post("/login", {
            login: email,
            password: password,
            captcha: captcha
        });
    }

    acl() {
        return instance.get("/acl");
    }

}

export default new ApiAuth();
