import log from "loglevel";
import { toast } from "react-toastify";
import { makeAutoObservable, toJS } from "mobx";
import { RootStore } from "./root-store";
import Api from "@api/api-transaction";
import ApiUser from "@api/api-user";

class TransactionsStore {

    private rootStore: RootStore|null;

    private _filters = {
        users: [],
        transaction_groups: [],
        transaction_statuses: [],
        transaction_currencies: [],
    }
    private _filters_values = {
        user: [],
        login: "",
        transaction_group: [],
        transaction_status: [],
        transaction_currency: [],
        tx: "",
        amount: {
            from: "",
            to: ""
        },
        period: {
            from: "",
            to: ""
        },
    }
    private _toast: any;

    get filters() {
        return this._filters;
    }
    set filters(val) {
        this._filters = val;
    }

    get filtersValues() {
        return this._filters_values;
    }
    set filtersValues(val) {
        this._filters_values = val;
    }

    setFilterValue(field: string, val: string) {
        log.debug("setFilterValue field", field, val);
        // @ts-ignore
        this.filtersValues[field] = val;
    }

    setFilterValueSubfield(field: string, subfield: string, val: string) {
        // @ts-ignore
        this.filtersValues[field][subfield] = val;
    }

    getTransactionsGroupTitle(transaction_group_id: number) {
        const arr = toJS(this.filters.transaction_groups);
        for (let item of arr) {
            // @ts-ignore
            if (item.value === transaction_group_id) {
                // @ts-ignore
                return item.label;
            }
        }
    }

    getTransactionsStatusTitle(transaction_status_id: number) {
        const arr = toJS(this.filters.transaction_statuses);
        for (let item of arr) {
            // @ts-ignore
            if (item.value === transaction_status_id) {
                // @ts-ignore
                return item.label;
            }
        }
    }

    filterReset() {
        this.filtersValues.user = [];
        this.filtersValues.login = "";
        this.filtersValues.transaction_group = [];
        this.filtersValues.transaction_status = [];
        this.filtersValues.tx = "";
        this.filtersValues.transaction_currency = this.filters.transaction_currencies[0];
        this.filtersValues.amount.from = "";
        this.filtersValues.amount.to = "";
        this.filtersValues.period.from = "";
        this.filtersValues.period.to = "";
    }


    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    async getUsers() {
        log.debug("getUsers");
        try {
            const res = await ApiUser.usersList();
            log.debug(res.data);
            if (res.data.status === 'OK') {
                this.filters.users = res.data.users.map((user: any) => {
                    return {
                        value: user.user_id,
                        label: user.login
                    }
                });
            }
        } catch (ex: any) {
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async initFilters() {
        log.debug("initFilters");
        //await this.getUsers();
        await this.getFilters();
        // @ts-ignore
        this.filters.transaction_currencies = [
            { value: 'BTC', label: 'BTC' },
            { value: 'USD', label: 'USD' }
        ].map(row => { return row });
        this.filtersValues.transaction_currency = this.filters.transaction_currencies[0];
    }

    async getFilters() {
        log.debug("getFilters");
        try {
            const res = await Api.filters();
            log.debug(res.data);
            if (res.data.status === 'OK') {
                this.filters.transaction_groups = res.data.filters.transaction_group.map((row: any) => {
                    return {
                        value: row.transaction_group_id,
                        label: row.title
                    }
                });
                this.filters.transaction_statuses = res.data.filters.transaction_status.map((row: any) => {
                    return {
                        value: row.transaction_status_id,
                        label: row.title
                    }
                });
            }
            console.log(toJS(this.filters));
        } catch (ex: any) {
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async getTransactions(data: any, callback: any, settings: any) {
        log.debug("getTransactions", data, callback, settings);
        // блокируем загрузку таблицы до загрузки данных фильтров
        if (!this.filters.transaction_groups.length) {
            return [];
        }
        try {
            data.filters = {
                login: this.filtersValues.login.trim(),
                // @ts-ignore
                user_id: this.filtersValues.user?.value,
                // @ts-ignore
                transaction_group_id: this.filtersValues.transaction_group?.value,
                // @ts-ignore
                transaction_status_id: this.filtersValues.transaction_status?.value,
                tx: this.filtersValues.tx,
                // @ts-ignore
                currency: this.filtersValues.transaction_currency.value,
                amount: this.filtersValues.amount,
                period: this.filtersValues.period
            }
            const res = await Api.transactions(data);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                callback(res.data);
            }
            this.rootStore?.setShowProgressBar(false);
        } catch (ex: any) {
            log.debug("ERROR", ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async disable(transaction_id: number) {
        log.debug("disable transaction_id", transaction_id);
        try {
            const res = await Api.disable(transaction_id);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                toast.success("Done", {
                    autoClose: 500,
                });
                return res.data;
            } else {
                toast.dismiss(this._toast);
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async delete(transaction_id: number) {
        log.debug("delete transaction_id", transaction_id);
        try {
            const res = await Api.delete(transaction_id);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                toast.success("Done", {
                    autoClose: 500,
                });
                return res.data;
            } else {
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

}

export default TransactionsStore;