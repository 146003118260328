import { instance } from "./service";

class ApiCron {

    cron() {
        return instance.get("/settings/cron");
    }

    job(cron_id: number) {
        return instance.get("/settings/cron/" + cron_id);
    }

    disable(cron_id: number) {
        return instance.get("/settings/cron/disable/" + cron_id);
    }

    start(cron_id: number) {
        return instance.get("/settings/cron/start/" + cron_id);
    }

    save(data: any) {
        return instance.post("/settings/cron/save", data);
    }

}

export default new ApiCron();
