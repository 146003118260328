import { RootStore } from "./root-store";
import { makeAutoObservable } from "mobx";

class MainStore {

    private rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

}

export default MainStore;