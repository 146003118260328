import React from 'react';
import AlertsMenu from "@components/navbar/alerts-menu";
import UserMenu1 from "@components/navbar/user-menu1";

const Navbar = () => {

    const onHamburgerClick = () => {
        // @ts-ignore
        window.toggleSidebar();
    }

    return (
        <nav className="navbar navbar-expand navbar-light navbar-bg">
            <a className="sidebar-toggle js-sidebar-toggle" onClick={onHamburgerClick}>
                <i className="hamburger align-self-center"></i>
            </a>
            <div className="navbar-collapse collapse">
                <ul className="navbar-nav navbar-align">
                    {/*<AlertsMenu />*/}
                    <UserMenu1 />
                </ul>
            </div>
        </nav>
    );
};

export default Navbar;
