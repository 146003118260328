import log from "loglevel";
import { instance } from "./service";

class ApiTransactions {

    filters() {
        log.debug("filters");
        return instance.get("/filters");
    }

    transactions(data: any) {
        log.debug("transactions", data);
        return instance.post("/transactions", {
            data: data
        });
    }

    disable(transaction_id: number) {
        return instance.get("/transactions/disable/" + transaction_id);
    }

    delete(transaction_id: number) {
        return instance.get("/transactions/delete/" + transaction_id);
    }
    
}

export default new ApiTransactions();
