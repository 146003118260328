import { instance } from "./service";

class ApiMmn {

    config() {
        return instance.get("/requests/mmn/config");
    }

    mmn(data: any) {
        return instance.post("/requests/mmn", {
            data: data
        });
    }

    save(data: any) {
        return instance.post("/requests/mmn/save", data);
    }

    disable(mmn_req_id: number) {
        return instance.get("/requests/mmn/disable/" + mmn_req_id);
    }

    delete(mmn_req_id: number) {
        return instance.get("/requests/mmn/delete/" + mmn_req_id);
    }

}

export default new ApiMmn();
