import { PropsWithChildren } from "react";
import { observer } from "mobx-react-lite";
import { Navigate } from "react-router-dom";
import { useStore } from "@hooks/use-store";

const ProtectedRoute = (props: PropsWithChildren<any>) => {

    const { authStore } = useStore();

    return authStore.isAuth()
        ? props.children
        : <Navigate to="/login" replace />;
}

export default observer(ProtectedRoute);