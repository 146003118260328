import { instance } from "./service";

class ApiServices {

    users(data: any) {
        return instance.post("/support/users", {
            data: data
        });
    }

    save(data: any) {
        return instance.post("/support/user/save", data);
    }

    rules(user_id: number) {
        return instance.get("/support/user/rules/" + user_id);
    }

    disable(user_id: number) {
        return instance.get("/support/user/ban/" + user_id);
    }

    delete(user_id: number) {
        return instance.get("/support/user/delete/" + user_id);
    }

}

export default new ApiServices();
