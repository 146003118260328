import _ from "lodash";
import log from "loglevel";
import Cookie from 'mobx-cookie';
import { toast } from "react-toastify";
import { makeAutoObservable } from "mobx";

import { RootStore } from "./root-store";
import Api from "@api/api-auth";

/**
 * Стор авторизации для страниц login
 */
class AuthStore {

    private rootStore: RootStore;

    public user?: string|null = null;
    public token?: string|null = null;
    private _authenticated: boolean = false;
    private _type = 0;
    private _acl = null;
    private _user_group_id = 0;

    public cookie = new Cookie('token');

    get authenticated() {
        return this._authenticated;
    }
    set authenticated(val) {
        this._authenticated = val;
    }

    get type() {
        return this._type;
    }
    set type(val) {
        this._type = val;
    }

    get acl() {
        return this._acl;
    }
    set acl(val) {
        this._acl = val;
    }

    get user_group_id() {
        return this._user_group_id;
    }
    set user_group_id(val) {
        this._user_group_id = val;
    }

    constructor(rootStore: any) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    isAuth() {
        const auth = localStorage.getItem("token") != null;
        return this.authenticated = auth;
    }

    getToken() {
        return localStorage.getItem("token");
    }

    setToken(token: string, refresh_token: string) {
        this.token = token;
        localStorage.setItem("token", token);
        localStorage.setItem("refresh_token", refresh_token);
        this.cookie.set(token);
    }

    async login(email: string, passwd: string, captcha: string) {
        log.debug("login email", email, passwd, captcha);
        try {
            const res = await Api.login(email, passwd, captcha);
            console.log("ADMIN", res);
            if (res.data.status === 'OK') {
                this.setToken(res.data.token, res.data.refresh_token);
                this.type = res.data.type;
                return true;
            }
        } catch (ex: any) {
            toast.error(ex.response?.data.message || ex.message);
        }
        return false;
    }

    logout() {
        log.debug("logout");
        this.token = null;
        this.authenticated = false;
        this.cookie.set("");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("token");
        document.location.href = "/login";
    }

    async getAcl() {
        log.debug("getAcl");
        try {
            const res = await Api.acl();
            console.log("ACL", res);
            if (res.data.status === 'OK') {
                this.acl = res.data.rules;
                this.user_group_id = res.data.user_group_id;
            }
        } catch (ex: any) {
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    /**
     * Проверка, что юзер входит в перечень допустимых групп
     * @param user_group_id     Группа или список допустимых групп
     */
    checkUserGroup(user_group_id: any) {
        let res = true;
        if (user_group_id) {
            res = _.isArray(user_group_id)
                ? user_group_id.includes(this.user_group_id)
                : user_group_id === this.user_group_id;
        }
        return res;
    }

    /**
     * Проверка есть ли у юзера нужные права доступа
     * @param acl       Запрашиваемое (требуемое) право доступа. В this.acl - имеющиеся у юзера права
     */
    checkAcl(acl: any) {
        let res = false;
        let res1 = null;
        if (acl) {
            if (_.isNumber(acl)) {
                // @ts-ignore
                res1 = this.acl?.filter((rule: any) => rule.acl_type_id === acl).pop();
                res = res1 && res1.enabled;
            }
            if (_.isArray(acl)) {
                res = false;
                for (let i of acl) {
                    // @ts-ignore
                    res1 = this.acl?.filter((rule: any) => rule.acl_type_id === i).pop();
                    if (res1 && res1.enabled) {
                        res = true;
                    }
                }
            }
        } else {
            res = true;
        }
        return res;
    }

}

export default AuthStore;