import { instance } from "./service";

class ApiFullzFormats {

    formats() {
        return instance.get("/data/fullz/formats");
    }

    save(data: any) {
        return instance.post("/data/fullz/format/save", data);
    }

    disable(format_id: number) {
        return instance.get("/data/fullz/format/disable/" + format_id);
    }

    delete(format_id: number) {
        return instance.get("/data/fullz/format/delete/" + format_id);
    }

}

export default new ApiFullzFormats();
