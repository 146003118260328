import cn from "classnames";
import log from "loglevel";
import React, { useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import logo from "@assets/logo.svg";

import { useStore } from "@hooks/use-store";
import { TypeRoute } from "../../types/type-route";
import routes from '@routing/routes';
import styles from './sidebar.module.scss';

const Sidebar = () => {

    const location = useLocation();
    const { authStore } = useStore();

    useEffect(() => {
        // для нормального отображения иконок сайдбара
        // @ts-ignore
        setTimeout(() => feather.replace(), 300);
    }, []);

    const onSidebarLinkClick = () => {
        log.debug("onSidebarLinkClick");
        // @ts-ignore
        window.toggleSidebar();
    }

    const sidebarLink = (row: TypeRoute) => {
        const access = authStore.checkAcl(row.acl) && authStore.checkUserGroup(row.user_groups);
        return access ? <Link className="sidebar-link" to={row.path} onClick={onSidebarLinkClick}>
            {row.icon && <i className="align-middle" data-feather={row.icon} />}
            {row.name}
        </Link> : "";
    }

    const submenu = (items: TypeRoute[]) => {
        return items.map((row: any, i: number) => {
            return <li key={i} className={cn("sidebar-item", row.path === location.pathname ? "active" : "")}>
                { sidebarLink(row) }
            </li>
        });
    }

    /**
     * Возвращает true для родителя, если активен дочерний пункт
     * @param row
     */
    const isParentActive = (row: TypeRoute) => {
        if (row.path === location.pathname)
            return true;
        if (row.childs) {
            for (let row2 of row.childs)
                if (row2.path === location.pathname)
                    return true;
        }
        return false;
    }

    const menu = routes.map((row: TypeRoute, i: number) => {
        const access = authStore.checkAcl(row.acl) && authStore.checkUserGroup(row.user_groups);
        return row.hidden || !access ? "" : (
            <li key={i} className={cn("sidebar-item ", isParentActive(row) ? "active" : "")}>
                {row.childs
                    ? <>
                        <a className="sidebar-link collapsed" data-bs-target={"#item" + i} data-bs-toggle="collapse" aria-expanded="false">
                            <i className="align-middle" data-feather={row.icon}/>
                            <span className="align-middle">{row.name}</span>
                        </a>
                        <ul id={"item" + i} className="sidebar-dropdown list-unstyled collapse" data-bs-parent="#sidebar">
                            { submenu(row.childs) }
                        </ul>
                    </>
                    : sidebarLink(row)
                }
            </li>
        );
    });

    return (
        <nav id="sidebar" className="sidebar js-sidebar">
        <div className="sidebar-content js-simplebar">
            <a className={"sidebar-brand " + styles.logo} href="/">
                <span className="align-middle"><img src={logo} alt="Logo"/></span>
            </a>
            <ul className="sidebar-nav">
                {menu}
            </ul>
        </div>
    </nav>
    );
};

export default Sidebar;
