import log from "loglevel";
import { RootStore } from "./root-store";
import { toast } from "react-toastify";
import { makeAutoObservable } from "mobx";
import Api from "@api/api-settings-services";

class ServicesStore {

    private rootStore: RootStore;

    private _services = [];

    get services() {
        return this._services;
    }
    set services(val: any) {
        this._services = val;
    }

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    async getServices() {
        log.debug("getServices");
        try {
            const res = await Api.services();
            log.debug(res.data);
            if (res.data.status === 'OK') {
                this.services = res.data.services;
            }
        } catch (ex: any) {
            log.debug("ERROR", ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async disable(service_id: number) {
        log.debug("disable service_id", service_id);
        try {
            const res = await Api.disable(service_id);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                toast.success("Done", {
                    autoClose: 500,
                });
                await this.getServices();
            } else {
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

}

export default ServicesStore;