import { instance } from "./service";
import log from "loglevel";

class ApiFullzData {


    data(data: any) {
        log.debug("data", data);
        return instance.post("/data/fullz/data", {
            data: data
        });
    }

    save(data: any) {
        return instance.post("/data/fullz/data/save", data);
    }

    disable(format_id: number) {
        return instance.get("/data/fullz/data/disable/" + format_id);
    }

    delete(format_id: number) {
        return instance.get("/data/fullz/data/delete/" + format_id);
    }

}

export default new ApiFullzData();
