import { RootStore } from "./root-store";
import { makeAutoObservable } from "mobx";

class ModalTicketStore {

    private rootStore: RootStore;

    private _message = "";
    private _attaches = [];

    get message() {
        return this._message;
    }
    set message(val: any) {
        this._message = val;
    }

    get attaches() {
        return this._attaches;
    }
    set attaches(val: any) {
        this._attaches = val;
    }

    clear() {
        this.message = "";
        this.attaches = [];
    }

    deleteAttache(num: number) {
        this.attaches.splice(num, 1);
    }

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

}

export default ModalTicketStore;