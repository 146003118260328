import log from "loglevel";
import { instance } from "./service";

class ApiNews {

    news(data: any) {
        return instance.post("/content/news", {
            data: data
        });
    }

    save(data: any) {
        return instance.post("/content/new", data);
    }

    disable(news_id: number) {
        return instance.get("/content/news/disable/" + news_id);
    }

    delete(news_id: number) {
        return instance.get("/content/news/delete/" + news_id);
    }

}

export default new ApiNews();
