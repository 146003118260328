import log from "loglevel";
import { toast } from "react-toastify";
import { RootStore } from "./root-store";
import { makeAutoObservable } from "mobx";
import Api from "@api/api-data-pros";

class ProsStore {

    private rootStore: RootStore;
    private _filters = {
        status: [],
        age: [],
        state: [],
        score: [],
        dl: false,
        mmn: false
    };
    private _states = [];
    private _toast: any;

    get filters() {
        return this._filters;
    }
    set filters(val: any) {
        this._filters = val;
    }

    get states() {
        return this._states;
    }
    set states(val: any) {
        this._states = val;
    }

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    setFilter(name: string, value: any) {
        this.filters[name] = value;
    }

    filterReset() {
        this.filters.status = [];
        this.filters.age = [];
        this.filters.state = [];
        this.filters.score = [];
        this.filters.dl = false;
        this.filters.mmn = false;
    }

    async getFilters() {
        log.debug("getFilters");
        try {
            const res = await Api.filters();
            if (res.data.status === 'OK') {
                this.states = res.data.data.state;
                return res.data.data;
            }
        } catch (ex: any) {
            log.debug("ERROR", ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async getPros(data: any, callback: any, settings: any) {
        log.debug("getPros", data, callback, settings);
        try {
            data.filters = {
                status: this.filters.status?.value,
                age: this.filters.age?.value,
                state: this.filters.state?.value,
                score: this.filters.score?.value,
                dl: this.filters.dl,
                mmn: this.filters.mmn
            }
            const res = await Api.pros(data);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                callback(res.data);
                this.rootStore.setShowProgressBar(false);
            }
        } catch (ex: any) {
            log.debug("ERROR", ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async save(data: any) {
        log.debug("save data", data);
        try {
            const res = await Api.save(data);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                return res.data;
            } else {
                toast.dismiss(this._toast);
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async disable(pros_id: number) {
        log.debug("disable pros_id", pros_id);
        try {
            const res = await Api.disable(pros_id);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                toast.success("Done", {
                    autoClose: 500,
                });
                return res.data;
            } else {
                toast.dismiss(this._toast);
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async delete(pros_id: number) {
        log.debug("delete pros_id", pros_id);
        try {
            const res = await Api.delete(pros_id);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                toast.success("Done", {
                    autoClose: 500,
                });
                return res.data;
            } else {
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

}

export default ProsStore;