import log from "loglevel";
import { toJS } from "mobx";
import { toast } from "react-toastify";
import { RootStore } from "./root-store";
import { makeAutoObservable } from "mobx";
import Api from "@api/api-data-fullz-parser";

class FullzParserStore {

    private rootStore: RootStore;

    private _toast: any;
    private _config: any;
    private _dob_formats: any;
    private _format_id = 0;
    private _price = 5;
    private _dob_format = -1;

    // region Getters & Setters

    get config() {
        return this._config;
    }
    set config(val: any) {
        this._config = val;
    }

    get dob_formats() {
        return this._dob_formats;
    }
    set dob_formats(val: any) {
        this._dob_formats = val;
    }

    get format_id() {
        return this._format_id;
    }
    set format_id(val: any) {
        this._format_id = val;
    }

    get price() {
        return this._price;
    }
    set price(val: any) {
        this._price = val;
    }
    get dob_format() {
        return this._dob_format;
    }
    set dob_format(val: any) {
        this._dob_format = val;
    }

    get formats() {
        return toJS(this.rootStore.fullzFormatsStore.formats);
    }
    // endregion

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    async loadParserConfig() {
        log.debug("loadParserConfig");
        try {
            const res = await Api.config();
            log.debug(res.data);
            if (res.data.status === 'OK') {
                this.config = res.data.config;
                this.dob_formats = res.data.dob_format;
            }
        } catch (ex: any) {
            toast.error(ex.response?.data.message || ex.message);
        }

    }

    async loadData() {
        log.debug("loadData");
        this.rootStore.setShowProgressBar(true);
        try {
            await this.loadParserConfig();
            await this.rootStore.fullzFormatsStore.loadFormats();
        } catch (ex: any) {
            toast.error(ex.message);
        }
        this.rootStore.setShowProgressBar(false);
    }

}

export default FullzParserStore;