import { instance } from "./service";

class ApiNotifications {

    notifications(data: any) {
        return instance.post("/content/notifications", {
            data: data
        });
    }

    save(data: any) {
        return instance.post("/content/notification", data);
    }

    disable(notify_id: number) {
        return instance.get("/content/notification/disable/" + notify_id);
    }

    delete(notify_id: number) {
        return instance.get("/content/notification/delete/" + notify_id);
    }

}

export default new ApiNotifications();
