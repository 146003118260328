import { instance } from "./service";

class ApiPros {

    filters() {
        return instance.get("/data/pros/filters");
    }

    pros(data: any) {
        return instance.post("/data/pros", {
            data: data
        });
    }

    save(data: any) {
        return instance.post("/data/pros/save", data);
    }

    disable(pros_id: number) {
        return instance.get("/data/pros/disable/" + pros_id);
    }

    delete(pros_id: number) {
        return instance.get("/data/pros/delete/" + pros_id);
    }

}

export default new ApiPros();
