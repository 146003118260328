import { instance } from "./service";

class ApiMenu {

    menu() {
        return instance.get("/content/menu");
    }

    save(data: any) {
        return instance.post("/content/menu", data);
    }

    delete(section_id: number) {
        return instance.get("/content/menu/delete/" + section_id);
    }

}

export default new ApiMenu();
