import { instance } from "./service";

class ApiFullzDatabases {

    databases() {
        return instance.get("/data/fullz/databases");
    }

    save(data: any) {
        return instance.post("/data/fullz/database/save", data);
    }

    disable(source_id: number) {
        return instance.get("/data/fullz/database/disable/" + source_id);
    }

    delete(source_id: number) {
        return instance.get("/data/fullz/database/delete/" + source_id);
    }

}

export default new ApiFullzDatabases();
