import log from "loglevel";
import { toast } from "react-toastify";
import { RootStore } from "./root-store";
import { makeAutoObservable } from "mobx";
import Api from "@api/api-data-fullz-data";

class FullzDataStore {

    private rootStore: RootStore;

    private _data = [];

    private _toast: any;

    // region Getters & Setters
    get data() {
        return this._data;
    }
    set data(val: any) {
        this._data = val;
    }
    // endregion

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    async loadData(data: any, callback: any, settings: any) {
        log.debug("getData", data, callback, settings);
        try {
            const res = await Api.data(data);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                callback(res.data);
            }
        } catch (ex: any) {
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async save(data: any) {
        log.debug("save data", data);
        try {
            const res = await Api.save(data);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                return true;
            } else {
                toast.dismiss(this._toast);
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async disable(data_id: number) {
        log.debug("disable data_id", data_id);
        try {
            const res = await Api.disable(data_id);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                toast.success("Done", {
                    autoClose: 500,
                });
                return res.data;
            } else {
                toast.dismiss(this._toast);
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async delete(data_id: number) {
        log.debug("delete data_id", data_id);
        try {
            const res = await Api.delete(data_id);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                toast.success("Done", {
                    autoClose: 500,
                });
                return res.data;
            } else {
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

}

export default FullzDataStore;