import { instance } from "./service";

class ApiEmails {

    domains() {
        return instance.get("/requests/emails/domains");
    }

    emails(data: any) {
        return instance.post("/requests/emails", {
            data: data
        });
    }

    save(data: any) {
        return instance.post("/requests/emails/save", data);
    }

    disable(req_id: number) {
        return instance.get("/requests/emails/disable/" + req_id);
    }

    delete(req_id: number) {
        return instance.get("/requests/emails/delete/" + req_id);
    }

}

export default new ApiEmails();
