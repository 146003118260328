import log from "loglevel";
import { instance } from "./service";

class ApiFinanceSettings {

    settings() {
        log.debug("settings");
        return instance.get("/finance/settings");
    }

    saveSettings(data: any) {
        log.debug("saveSettings");
        return instance.post("/finance/settings", data);
    }

}

export default new ApiFinanceSettings();
