import log from "loglevel";
import { RootStore } from "./root-store";
import { makeAutoObservable } from "mobx";
import Api from "@api/api-auth";

class LoginStore {

    private rootStore: RootStore;

    public _captcha: string = "Loading..";

    public data = {
        email: "",
        password: "",
        captcha: "",
    }

    get captcha() {
        return this._captcha;
    }
    set captcha(captcha: string) {
        this._captcha = captcha;
    }

    get email() {
        return this.data.email;
    }
    set email(email: string) {
        this.data.email = email;
    }

    get password() {
        return this.data.password;
    }
    set password(password: string) {
        this.data.password = password;
    }

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    async login() {
        log.debug("sendLoginForm");
        await this.rootStore.authStore.login(this.email, this.password, this.data.captcha);
    }

    /**
     * Обновление картинки капчи
     */
    async getCaptcha() {
        log.debug("getCaptcha");
        let result;
        try {
            const res = await Api.captcha();
            result = res.data;
        } catch (ex: any) {
            result = ex.message;
        }
        this._captcha = result;
    }

}

export default LoginStore;